import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";

const HeroSection = (props) => {
  const [openVideo, setOpenVideo] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);

  const hideModalHandler = () => {
    setOpenVideo(false);
  };

  return (
    <div className="hero-sec-container">
      <div className="banner-section">
        {/* <div className="csm-batch-start">
          <em>Batch starts August 29, 2024 </em>
        </div> */}
        <div className="csm-title">
          {/* Start your writing
          <br />
          career in <span className="circleOver"> 2024! </span> */}
          <img
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yqku5m8yf6uvx.jpg"
            loading="lazy"
            placeholder="blurred"
          />
        </div>
        <div className="sub-title">
          <div className="one">#1</div>
          <h1 className="desc">
            {" "}
            Creator Marketing school for students, freshers & professionals
          </h1>
        </div>

        <div className="ticks">
          <div className="tick">
            <img
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a7uey9.png"
              loading="lazy"
              placeholder="blurred"
            />
            Learn how to build brand through social and content
          </div>
          {/* <div className="tick">
            <img
              src={bulletIcon}
              loading="lazy"
              placeholder="blurred"
            />
            Real accountability- If the job {"<"} ₹4.5L,
            <br className="pc-none" /> we refund 60%
          </div> */}
          <div className="tick">
            <img
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a7uey9.png"
              loading="lazy"
              placeholder="blurred"
            />
            Get placements with CTCs upto ₹9L and freelancing gigs with top
            brands.
          </div>
        </div>
        <div className="placement-companies">
          <div className="title">Students placed in:</div>
          <div
            className="companies-container"
            onMouseEnter={() => setMouseHover(true)}
            onMouseLeave={() => setMouseHover(false)}
          >
            {!mouseHover ? (
              <div className="main-container">
                <div className="company">
                  <StaticImage
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21normu.webp"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
                <div className="company largeImg">
                  <StaticImage
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a0kr5r.png"
                    loading="lazy"
                    placeholder="blurred"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
                <div className="company">
                  <StaticImage
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a27x6c.png"
                    loading="lazy"
                    placeholder="blurred"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
                <div className="company">
                  <StaticImage
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a40pw3.png"
                    loading="lazy"
                    placeholder="blurred"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
                <div className="company">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a195vq.png"
                    loading="lazy"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
              </div>
            ) : (
              <div className="main-container">
                <div className="company largeImg">
                  <StaticImage
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a0b6mz.png"
                    loading="lazy"
                    placeholder="blurred"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
                <div className="company nxtwave">
                  <StaticImage
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a1yq4t.WEBP"
                    loading="lazy"
                    placeholder="blurred"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
                <div className="company">
                  <StaticImage
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a0wqp1.png"
                    loading="lazy"
                    placeholder="blurred"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
                <div className="company">
                  <StaticImage
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a0epjh.webp"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
                <div className="company">
                  <StaticImage
                    src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a2epvv.webp"
                    onMouseEnter={() => setMouseHover(true)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {openVideo ? (
        <React.Fragment>
          <div
            className="spec-mask spec-active"
            onClick={hideModalHandler}
            aria-hidden="true"
          ></div>
          <div className="spec-modal">
            <img
              src="https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_main/cross-white.svg"
              alt="cross icon"
              className="spec-modal-cross"
              onClick={hideModalHandler}
              aria-hidden="true"
            />
            <LazyLoad>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/QgDxj-1wqDo?autoplay=1"
                title="Kraftshala YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="youtube-player"
              ></iframe>
            </LazyLoad>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default HeroSection;
